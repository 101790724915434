<template>
  <v-container>
    <v-card-title>STATUTO E REGOLAMENTI</v-card-title>
    <v-card-text>
      Sono disponibili lo statuto ed i regolamenti vigenti dell'associazione:<br>
      <br>
      <a
        v-for="(stat,i) in statuto"
        :key="i"
        :href="backendurl + '/documents/' + stat.document"
      >
          <v-icon
            small
          >
            mdi-file-pdf-box
          </v-icon>
          {{ stat.filename }}
        <br>
      </a>
    </v-card-text>
  </v-container>
</template>

<script>
// const axios = require("axios");
const axios = require("axios");
const config = require("@/config");

export default {
  name: "Statuto",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
  },
  created: function () {
    var component = this
    axios
      .get(component.backendurl + "/sitesettings/statuto", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
            component.statuto = response.data.setting
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    statuto: [],
    backendurl: config.backendurl,
  }),
  methods: {
    formattedText (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
  },
};
</script>
<style >
.v-card .v-card__text,
.v-card .v-card__subtitle,
.v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333 !important;
}
 .v-application .v-card .v-card__text a {
  text-decoration: none;
  color: #333333;
}
</style>
