<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <v-snackbar
        v-model="snackbar"
        timeout="5000"
        :color="snackbarColor"
      >
      {{ snackbarText }}
      </v-snackbar>
      <v-row justify="center">
        <v-spacer v-if="$store.state.mobile" />
        <v-col :cols="$store.state.mobile ? 12 : 6">
          <v-card elevation="8">
            <v-card-subtitle>
              {{ snackbarText }}
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import CookieLaw from "vue-cookie-law";
const config = require("@/config");
const axios = require("axios");

export default {
  name: "RegisterConfirm",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
  },

  data: () => ({
    submitted: false,
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'primary',
  }),
  methods: {
  },
  created: function () {
    var component = this
    axios
        .post(config.backendurl + "/members/register/" + component.$route.params.code, {
          headers: {
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            component.submitted = true
            component.snackbarText = "La tua registrazione è stata confermata, ti ringraziamo per l'interesse. A breve sarai rediretto sulla home page del sito."
            setTimeout(function(){ component.$router.push({ path: '/' }) }, 6000);
          } else if (response.data.httpStatus === 501) {
            // Utente già esistente
            component.snackbarText = "Il codice non è valido o la richiesta di registrazione è scaduta."
            component.snackbarColor = 'error'
            component.snackbar = true
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
            component.snackbarText = response.data.longMessage
            component.snackbarColor = 'error'
            component.snackbar = true
          }
        })
        .catch(function (response) {
          // error callback
          console.log(response)
          component.snackbarText = "Errore di rete"
          component.snackbarColor = 'error'
          component.snackbar = true
          component.$router.push({ path: '/problems' })
        });
  },
  computed: {
  },
};
</script>
