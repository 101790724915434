<template>
    <v-container>
    <v-btn
        v-for="(link, i) in $store.state.links"
        :key="i"
        text
        :href="link.href"
        justify="end"
        class="grey--text text--darken-2"
    >
        <span v-if="loggedin">
        <v-icon v-if="link.desktopicon">{{link.iconalt}}</v-icon>{{ link.text }}
        </span>
        <span v-else>
        <v-icon v-if="link.desktopicon">{{link.icon}}</v-icon>{{ link.text }}
        </span>
    </v-btn>
    </v-container>
</template>
<script>
const config = require("@/config")
const jwt = require('jsonwebtoken')
export default {
  name: "MenuDesktop",
  data: () => ({
  }),
  computed: {
      loggedin: function () {
        var loggedIn = localStorage.getItem(config.authToken)
        if (loggedIn) {
            loggedIn = loggedIn.toString()
            var payload = jwt.decode(loggedIn)
            if (payload.exp < Math.floor(Date.now() / 1000)) {
                // JWT expired
                localStorage.removeItem(config.authToken)
                return false
            } else {
                // JWT valid
                return true
            }
        } else {
            return false
        }
      },
  },
};
</script>