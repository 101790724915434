<template>
  <v-container>
    <v-card-title>DOCUMENTAZIONE</v-card-title>
    <v-card-text>
      <br>
      <a
        v-for="(doc,i) in documentazione"
        :key="i"
        :href="backendurl + '/documents/' + doc.document"
      >
          <v-icon
            small
          >
            {{ iconname(doc.filename) }}
          </v-icon>
          {{ doc.filename }}
        <br>
      </a>
    </v-card-text>
  </v-container>
</template>

<script>
// const axios = require("axios");
const axios = require("axios");
const config = require("@/config");
const path = require('path')

export default {
  name: "Documentazione",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
  },
  created: function () {
    var component = this
    axios
      .get(component.backendurl + "/sitesettings/documentazione", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
            component.documentazione = response.data.setting
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    documentazione: [],
    backendurl: config.backendurl,
  }),
  methods: {
    formattedText (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    iconname(filename) {
      var ext = path.extname(filename)
      if(ext === '.doc' || ext === '.DOC' || ext === '.docx' || ext === '.DOCX') {
        return 'mdi-file-word'
      } else if (ext === '.pdf' || ext === '.PDF') {
        return 'mdi-file-pdf-box'
      } else {
        return 'mdi-file-pdf-box'
      }
    },
  },
};
</script>
<style >
.v-card .v-card__text,
.v-card .v-card__subtitle,
.v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333 !important;
}
 .v-application .v-card .v-card__text a {
  text-decoration: none;
  color: #333333;
}
</style>
