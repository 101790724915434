import { render, staticRenderFns } from "./HomeCalendar.vue?vue&type=template&id=2b99baac&scoped=true"
import script from "./HomeCalendar.vue?vue&type=script&lang=js"
export * from "./HomeCalendar.vue?vue&type=script&lang=js"
import style0 from "./HomeCalendar.vue?vue&type=style&index=0&id=2b99baac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b99baac",
  null
  
)

export default component.exports