<template>
  <v-container>
    <v-card-title>PRESENTAZIONE</v-card-title>
    <v-card-text v-html="presentationtext">
    </v-card-text>
  </v-container>
</template>

<script>
const axios = require("axios");
const config = require("@/config");

export default {
  name: "Presentazione",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
  },
  created: function () {
    var component = this
    axios
      .get(component.backendurl + "/sitesettings/presentation", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
            component.presentationtext = component.formattedText(response.data.setting.text)
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    presentationtext: '',
    backendurl: config.backendurl,
  }),
  methods: {
    formattedText (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
  },
};
</script>
<style >
.v-card .v-card__text,
.v-card .v-card__subtitle,
.v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333 !important;
}
.v-application .v-list-item a {
  text-decoration: none;
  color: #333333;
}
</style>
