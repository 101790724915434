<template>
  <v-row justify="center" class="mb-8">
    <HomeArticlesCol 
    v-for="(content, i) in contents"
        :key="i"
        :article="content"
        :index="i"
    >    
    </HomeArticlesCol>
    
  </v-row>
</template>

<script>

import HomeArticlesCol from './HomeArticlesCol';



export default {
  name: "HomeArticlesRow",
  components : {
    HomeArticlesCol
  },
  props:    ['contents', 'index'],
  created: function () {
    
  },
  data: () => ({
    
  }),
};
</script>
