<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main app>
    <v-row justify="center" class="mb-7">
      <v-col :cols="$store.state.mobile? 12 : 6">
        <v-img
          :src="backendurl + '/images/277'"
          justify="center"
          dark
          color="primary"
          contain
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-7">
      <v-col :cols="$store.state.mobile? 12 : 6">
        <v-card
          elevation="8"
        >
        <v-container>
        <v-card-title light>INDICE</v-card-title>
        <v-card-text>
        <v-btn
          text
          @click="$vuetify.goTo('#presentation', options)"
        >
        - Presentazione
        </v-btn>
        <br>
        <v-btn
          text
          @click="$vuetify.goTo('#statuto', options)"
        >
        - Statuto e Regolamenti
        </v-btn>
        <br>
        <v-btn
          text
          @click="$vuetify.goTo('#trasparenza', options)"
        >
        - Trasparenza
        </v-btn>
        <br>
        <v-btn
          text
          @click="$vuetify.goTo('#boards', options)"
        >
        - Organi
        </v-btn>
        <br>
        <v-btn
          text
          @click="$vuetify.goTo('#affiliazioni', options)"
        >
        - Affiliazioni
        </v-btn>
        <br>
        <v-btn
          text
          @click="$vuetify.goTo('#documentazione', options)"
        >
        - Documentazione
        </v-btn>
        <br>
        </v-card-text>
        </v-container>
        <Presentation id="presentation" />
        <Statuto id="statuto" />
        <Trasparenza id="trasparenza" />
        <Boards id="boards" />
        <Affiliazioni id="affiliazioni" />
        <Documentazione id="documentazione" />
        </v-card>
      </v-col>
    </v-row>
  </v-main>
  <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import Presentation from "./Presentation";
import Statuto from "./Statuto";
import Trasparenza from "./Trasparenza";
import Boards from "./Boards";
import Affiliazioni from "./Affiliazioni";
import Documentazione from "./Documentazione";
import Footer from "./Footer";
import CookieLaw from 'vue-cookie-law'
const config = require("@/config");

export default {
  name: "ChiSiamo",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    Presentation,
    Statuto,
    Boards,
    Footer,
    CookieLaw,
    Trasparenza,
    Affiliazioni,
    Documentazione
  },
  created: function () {
  },
  data: () => ({
    options: {
      duration: 1000,
      offset: 0,
      easing: 'easeInOutCubic',
    },
    backendurl: config.backendurl,
  }),
  methods: {
  },
};
</script>
<style >
.v-card .v-card__text,
.v-card .v-card__subtitle,
.v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333 !important;
}
.v-application .v-list-item a {
  text-decoration: none;
  color: #333333;
}
</style>
