<template>
  <v-container>
    <v-row justify="center" class="mb-7">
      <v-col :cols="$store.state.mobile? 12 : 6">
        <v-img
          :src="require('@/assets/brokenlink.jpg')"
          justify="center"
          dark
          color="primary"
          contain
        >
        <v-sheet color="danger" dark class="mt-3 mx-3 px-2" rounded>
          <br>
          <h2>Siamo spiacenti, questa pagina &egrave; inesistente.</h2>
          <br>
        </v-sheet>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "ArticleContent",
  components: {
  },
  created: function () {},
  methods: {
    
  },
  data: () => ({
    
  }),
};
</script>
