<template>
  <v-footer
    padless
  >
    <v-card
      flat
      tile
      width="100%"
      class="footer lighten-1 text-center"
    >
      <v-card-text class="white--text footer">
        &copy;{{ new Date().getFullYear() }} <strong>ARCUS - Milano</strong><br>
        C.F. 97746450150 - P.IVA 09607720969
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
  },
  created: function () {
  },
  data: () => ({
  }),
  methods: {
  },
};
</script>
<style>
.v-footer .v-card .v-card__text {
  background-color: #003366;
  color: #cccccc !important;
}
</style>
