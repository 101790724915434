<template>
  <v-container>
    <v-card-title>TRASPARENZA</v-card-title>
    <v-card-text>
      {{ trasparenza.text }}
      <br>
      <br>
      <a v-for="(document,i) in trasparenza.documents"
        :key="i"
        :href="backendurl + '/documents/' + document.id">
          <v-icon
            v-if="document.mimetype === 'application/pdf'"
            small
          >
            mdi-file-pdf-box
          </v-icon>
          <v-icon
            v-else-if="document.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
            small
          >
            mdi-file-word
          </v-icon>
          <v-icon
            v-else-if="document.mimetype === 'application/x-cfb'"
            small
          >
            mdi-file-word
          </v-icon>
          {{ document.realfilename }}
          <br>
        </a>
    </v-card-text>
  </v-container>
</template>

<script>
// const axios = require("axios");
const axios = require("axios");
const config = require("@/config");

export default {
  name: "Statuto",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
  },
  created: function () {
    var component = this
    axios
      .get(component.backendurl + "/sitesettings/trasparenza", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
            component.trasparenza = response.data.setting
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    trasparenza: {
      text: '',
      documents: [],
    },
    backendurl: config.backendurl,
  }),
  methods: {
    formattedText (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
  },
};
</script>
<style >
.v-card .v-card__text,
.v-card .v-card__subtitle,
.v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333 !important;
}
 .v-application .v-card .v-card__text a {
  text-decoration: none;
  color: #333333;
}
</style>
