<template>
  <span />
</template>
<script>
  const config = require("@/config")
  export default {
    name: 'Logout',
    data: () => ({
    }),
    created: function () {
      localStorage.removeItem(config.authToken)
      this.$router.push({ path: '/' })
    },
  }
</script>
