require('./overrides.sass')

const preset = {
  theme: {
    themes: {
      light: {
        primary: '#003366',
        secondary: '#6B38FB',
        danger: '#ee1111'
      },
    },
  },
}

module.exports = { preset }
