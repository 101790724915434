<template>
  <v-container>
    <v-row justify="center" class="mb-7">
      <v-spacer />
      <v-col
        class="text-center justify-center"
        :cols="$store.state.mobile? 12 : 6"
        jsutify-self="center"
      >
        <v-img
          :src="article.image ? backendurl + '/images/' + article.image : ''"
          justify="center"
          dark
          color="primary"
          contain
        >
        <v-row justify="center" class="mb-7">
          <v-col cols="4" justify="center">
            <v-chip
              class="mx-1 px-1"
              color="primary"
              rounded
              dark
              x-small
              v-for="(category, i) in article.categories"
              :key="i"
            >
              {{ category }}
            </v-chip>
          </v-col>
          <v-spacer />
        </v-row>

        <v-img
          v-if="article.state=='suspended'"
          :src="require('@/assets/sospesa.png')"
        >
        </v-img>
        <v-img
          v-if="article.state=='canceled'"
          :src="require('@/assets/annullata.png')"
        >
        </v-img>
        <v-img
          v-if="article.state=='postponed'"
          :src="require('@/assets/rinviata.png')"
        >
        </v-img>
        </v-img>
      </v-col>
    <v-spacer />
    </v-row>
    <!--v-row justify="center" class="mb-7">
      <v-spacer />
      <v-col cols="5" justify="center">
        <v-chip
          class="mx-1 px-1"
          color="primary"
          rounded
          dark
          x-small
          v-for="(category, i) in article.categories"
          :key="i"
        >
          {{ category }}
        </v-chip>
      </v-col>
      <v-spacer />
    </v-row-->
    <v-row justify="center" class="mb-5">
      <v-col :cols="$store.state.mobile? 12 : 6">
        <v-card class="pa-2 pb-8" align="top" elevation="8">
          <v-card-title>{{ article.title }}</v-card-title>
          <v-card-subtitle class="grey--text text--darken-6 font-weight-bold text-body-1">{{
            article.abstract
          }}</v-card-subtitle>
          <!-- v-card-text v-html="formattedText" class="grey--text text--darken-4 font-weight-medium text-body-2"></v-card-text-->
          <v-card-text
            v-for="(art,i) in articleArray"
            :key="i"
            class="grey--text text--darken-4 font-weight-regular text-body-2 pt-0 mt-0 pb-0 mb-0"
          >
            <v-sheet
              v-html="formattedText(art.text)"
              class="grey--text text--darken-4 font-weight-medium text-body-2"
            />
            <v-sheet v-if="art.video">
              <youtube :video-id="art.video" host="https://www.youtube-nocookie.com" player-width="100%"></youtube>
            </v-sheet>
          </v-card-text>
          <br>
          <v-card-text>
              <v-list-item
                v-for="(document,i) in article.documents"
                :key="i"
              >
                <a :href="backendurl + '/documents/' + document.id">
                <v-icon
                  v-if="document.mimetype === 'application/pdf'"
                  small
                >
                  mdi-file-pdf-box
                </v-icon>
                <v-icon
                  v-else-if="document.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                  small
                >
                  mdi-file-word-box
                </v-icon>
                <v-icon
                  v-else-if="document.mimetype === 'application/x-cfb'"
                  small
                >
                  mdi-file-word-box
                </v-icon>
                {{ document.realfilename }}
                </a>
              </v-list-item>
          </v-card-text>
          <v-card-text>
              <v-list-item
                v-for="(photogallery,i) in article.photogalleries"
                :key="i"
              >
                <a :href="'/gallery/' + photogallery.id">
                <v-icon
                  small
                >
                  mdi-image-multiple-outline
                </v-icon>
                PHOTOGALLERY: {{ photogallery.title }}
                </a>
              </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const config = require("@/config");

export default {
  name: "ArticleContent",
  components: { },
  props: ["article"],
  created: function () {
  },
  methods: {
    formattedText: function (articletext) {
      // var component = this;
      if (articletext) {
        return articletext.replace(/(?:\r\n|\r|\n)/g, "<br>");
      } else {
        return ''
      }
    },
  },
  computed: {
    articleArray: function () {
      var parts = []
      var str = this.article.text
      var looping = true
      while (looping) {
        var regex = /:::video:[a-zA-Z0-9_-]+:::/g
        var result = regex.exec(str)
        if(!result) {
          parts.push({ text: str, video: null })
          break
        }
        var videostring = result[0]
        var video = videostring.substr(9).slice(0,-3)
        var beforeVideo = str.substr(0,result['index'])
        var aftermatch = str.substr(result['index']+videostring.length)
        parts.push({ text: beforeVideo, video: video})
        str = aftermatch
      }
      return parts
    },
  },
  data: () => ({
    backendurl: config.backendurl,
    contentArray: [],
  }),
};
</script>
<style>
.theme--light .v-card .v-card__text,
.theme--light .v-card .v-card__subtitle,
.theme--light .v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333;
}
.v-application .v-list-item a {
  text-decoration: none;
  color: #333333;
}
.v-application .v-card .v-card__text .v-sheet a {
  color: #003366;
  font-size: 115%;
  font-weight: bold;
}
</style>