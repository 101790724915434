<template>
    <v-container>
    <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
    <v-navigation-drawer
      app
      v-model="drawer"
      right
      temporary
      absolute
      @click="drawer=!drawer"
    >
      <v-row justify="right">
        <v-spacer />
        <v-col cols="4">
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
        </v-col>
      </v-row>
    <v-list>
      <v-list-item v-for="(item, i) in $store.state.links" :key="i">
          <v-btn
          v-if="loggedin"
        text
        :href="item.href"
        class="grey--text text--darken-2"
        >
          <v-list-item-icon><v-icon v-text="item.iconalt"></v-icon></v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-btn>
          <v-btn
          v-else
        text
        :href="item.href"
        class="grey--text text--darken-2"
        >
          <v-list-item-icon><v-icon v-text="item.icon"></v-icon></v-list-item-icon>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-btn>
      </v-list-item>
    </v-list>
    </v-navigation-drawer>
    </v-container>
</template>
<script>
const config = require("@/config")
const jwt = require('jsonwebtoken')
export default {
  name: "MenuMobile",
  data: () => ({
    drawer: false,
  }),
  computed: {
      loggedin: function () {
        var loggedIn = localStorage.getItem(config.authToken)
        if (loggedIn) {
            loggedIn = loggedIn.toString()
            var payload = jwt.decode(loggedIn)
            if (payload.exp < Math.floor(Date.now() / 1000)) {
                // JWT expired
                localStorage.removeItem(config.authToken)
                return false
            } else {
                // JWT valid
                return true
            }
        } else {
            return false
        }
      },
  },
};
</script>