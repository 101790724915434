<template>
  <v-container>
    <v-row
      v-if="$store.state.mobile"
      justify="center"
    >
      <v-spacer v-if="$store.state.mobile" />
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="searchValue"
          label="Cerca..."
          color="primary"
          hide-details
          solo
          dense
          style="max-width: 300px;"
          @input="debouncedOnChange"
          @keyup="debouncedOnChange"
          :loading="isLoading"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      v-else
      justify="center"
    >
      <v-spacer />
      <v-col
        md="3"
      >
        <v-text-field
          v-model="searchValue"
          label="Cerca..."
          color="primary"
          hide-details
          solo
          dense
          full-width
          @input="debouncedOnChange"
          @keyup="debouncedOnChange"
          :loading="isLoading"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
      <v-spacer />
    </v-row>
    <CategoryChooser />
    
    <HomeArticlesRow
      v-for="(content, i) in $store.state.contents"
      :key="i"
      :contents="content"
      :index="i"
    >
    </HomeArticlesRow>
  </v-container>
</template>

<script>
const config = require("@/config");
const axios = require("axios");

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

import CategoryChooser from "./CategoryChooser";
import HomeArticlesRow from "./HomeArticlesRow";
import _ from 'lodash';

function getContents(contents) {
  var rows = [];
  contents.forEach(function (content) {
    rows.push(content);
  });
  return rows;
}
function prepareSelectedArticles(contents, category) {
  var counter = 0;
  var rowCounter = -1;
  var rows = [];
  contents.forEach(function (article) {
    if (category == "TUTTO" || article.categories.includes(category)) {
      if (counter % 3 == 0) {
        rowCounter += 1;
        rows[rowCounter] = [];
      }
      counter += 1;
      rows[rowCounter].push(article);
    }
  });
  return rows;
}

export default {
  name: "HomeArticles",
  components: {
    CategoryChooser,
    HomeArticlesRow,
  },
  created: function () {
  },
  methods: {
    setSelected(name) {
      var component = this;
      component.$store.state.contents = prepareSelectedArticles(
        component.$store.state.allContents,
        name
      );
      component.$store.state.categorySelected = name
    },
    submitSearch() {
      var terms = this.searchValue.split(' ')
      var previousterms = this.previousValue.split(' ')
      var validterms = []
      var component = this
      terms.forEach(term => {
        if(term.length >= 3) {
          validterms.push(term)
        }
      })
      if(validterms.length != previousterms.length || component.searchValue != component.previousValue) {
        if(validterms.length > 0) {
          this.isLoading = true
          axios
            .get(component.backendurl + "/contents/search/" + encodeURIComponent(validterms.join(' ')), { headers: { ARCUSSession: component.$session.id() } })
            .then(function (response) {
              if(response.data.httpStatus === 200) {
                component.$store.state.allContents = getContents(
                  response.data.contents
                );
                component.$store.state.notices = getContents(
                  response.data.notices
                );
                // console.log(component.$store.state.notices)
                component.setSelected("TUTTO");
                setTimeout(component.endSearch,700)
              } else if(response.data.httpStatus === 503) {
                component.$router.push({ path: '/problems' })
              }
            })
            .catch(function (response) {
              console.log(response);
              component.$router.push({ path: '/problems' })
            });
        } else {
          axios
            .get(component.backendurl + "/contents", { headers: { ARCUSSession: component.$session.id() } })
            .then(function (response) {
              if(response.data.httpStatus === 200) {
                component.$store.state.allContents = getContents(
                  response.data.contents
                );
                component.$store.state.notices = getContents(
                  response.data.notices
                );
                // console.log(component.$store.state.notices)
                component.setSelected("TUTTO");
                setTimeout(component.endSearch,700)
              } else if(response.data.httpStatus === 503) {
                component.$router.push({ path: '/problems' })
              }
            })
            .catch(function (response) {
              console.log(response);
              component.$router.push({ path: '/problems' })
            });
        }
      }
      component.previousValue = component.searchValue
    },
    endSearch() {
      var component = this
      component.isLoading = false
    }
  },
  computed: {
    debouncedOnChange () {
      return _.debounce(this.submitSearch, 500);
    }
  },
  data: () => ({
    backendurl: config.backendurl,
    searchValue: '',
    previousValue: '',
    isLoading: false,
  }),
};
</script>
<style>
  .v-dialog {
    box-shadow: none;
  }
  .v-dialog__content .v-dialog--active {
    box-shadow: none;
  }
  button.mycolors span.v-btn__content {
    color: #333333;
  }

</style>