const path = require('path');
require('dotenv').config({ path: path.join(__dirname, '.env') });

const config = {
    backendurl: "https://api.arcus.unimi.it/arcus",
    maxCalendarEvents: 4,
    authToken: 'ARCUS_auth_token',
}

module.exports = config;
