<template>
  <v-container>
    <v-row justify="center" align="start" class="calendarheading">
      <v-col cols="3">
        <v-btn
          icon
          color="primary"
          class="text-left"
          @click="prevmonth"
        >
          <v-icon small>mdi-arrow-left-bold</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="text-center calendartitle"
      >
        {{ calendarTitle }}
      </v-col>
      <v-col cols="3">
        <v-btn
          icon
          color="primary"
          class="text-right"
          @click="nextmonth"
        >
          <v-icon small>mdi-arrow-right-bold</v-icon>
        </v-btn>
      </v-col>
    </v-row>
      <v-calendar
        ref="nextevents"
        v-model="calendarvalue"
        :weekdays="[1,2,3,4,5,6,0]"
        :type="'month'"
        :events="events"
        :event-height="8"
        :event-color="getEventColor"
        :event-overlap-mode="'stack'"
        event-overlap-threshold="8"
        @change="calendarChanged"
        md="3"
        locale="it"
      >
        <template v-slot:event="{ event }">
          <v-tooltip
            v-model="tooltips[event.counter]"
            top
            :open-on-click="true"
            :open-on-hover="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="width: 100%; height: 100%;"
                @click="removeAllTooltips"
              ></div>
            </template>
            <div>
              <router-link :to="'/article/' + event.id">
              <div :class="event.color + ' legenda'" style="border: 1px solid white;"></div>{{ event.name }}
              </router-link>
            </div>
          </v-tooltip>
        </template>
      </v-calendar>
  </v-container>
</template>
<script>
const axios = require("axios");
const dateFormat = require('dateformat')
const config = require("@/config");
dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab',
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',
    ],
    monthNames: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic',
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  }

export default {
    name: "HomeCalendar2",
    data: () => ({
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'red', 'pink', 'lime', 'yellow', 'amber', 'brown', 'blue-grey'],
        // colors: ['gray', 'red', 'orange', 'yellow', 'green', 'teal', 'blue', 'purple', 'pink'],
        calendarvalue: '',
        calendarTitle: dateFormat(new Date(), 'mmmm yyyy'),
        currentMonth: dateFormat(new Date(), 'yyyy-mm-dd'),
        attributes: [],
        backendurl: config.backendurl,
        tooltips: [],

    }),
    created: function () {
      var component = this
      axios
      .get(component.backendurl + "/contents/next", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
        if(response.data.httpStatus === 200) {
            var counter = 0
            var colorcounter = 0
            var mycolors = []
            var color = ''
            response.data.contents.forEach(element => {
            component.tooltips[counter] = false
            // var color = component.colors[Math.floor(Math.random() * component.colors.length)]
            if (mycolors[element.id]) {
              color = mycolors[element.id]
            } else {
              color = component.colors[colorcounter % component.colors.length]
              mycolors[element.id] = color
              colorcounter += 1
            }
            component.events.push({
              timed: false,
              start: new Date(element.eventstart),
              end: new Date(element.eventend),
              color: color,
              bar: color,
              name: element.title,
              id: element.id,
              counter: counter,
            })
            counter += 1
          })
        } else if(response.data.httpStatus === 503) {
          component.$router.push({ path: '/problems' })
        }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
    },
    mounted() {
      this.$refs.nextevents.checkChange()
    },
    computed: {
    },
    methods: {
      removeAllTooltips() {
        var component = this
        for(var i=0; i<component.tooltips.length;i++) {
          component.tooltips[i] = false
        }
      },
      calendarChanged () {
        if (this.$refs.nextevents) {
          this.calendarTitle = this.$refs.nextevents.title
        }
      },
      followLink(event) {
        console.log(event.event.id)
      },
      prevmonth() {
        this.removeAllTooltips()
        this.$refs.nextevents.prev()
      },
      nextmonth() {
        this.removeAllTooltips()
        this.$refs.nextevents.next()
      },
      getEvents() {

      },
      dayclicked(day) {
        console.log(day)
        return false
      },
      getEventColor(event) {
        return event.color
      },
      eventClicked(event) {
        console.log(event)
      },
    },
}
</script>
<style scoped>
  .eventContent {
    text-overflow: ellipsis;
    padding-top: 1px;
    font-size: 9px;
  }
  .v-tooltip__content .legenda {
    width: 12px;
    height: 6px;
    display: inline-block;
    margin-right: 3px;
    border: 1px solid white;
  }
  .container {
    padding: 0px;
  }
  .row .calendarheading {
    height: 40px;
  }
  .row .calendarheading .col {
    height: 25px;
    margin-top: 0px;
    padding-top: 0px;
    vertical-align: top;
    color: #003366;
    text-transform: uppercase;
  }
  .row .calendarheading .col .v-btn {
    height: 10px;
    width: 10px;
    vertical-align: top;
    margin-top: 5px;
    padding-top: 5px;

  }
.v-tooltip__content {
  pointer-events: initial;
  background: rgba(30, 30, 30, 0.9)
}
.v-tooltip__content a {
  text-decoration: none;
  color: #cccccc;
}
.calendartitle {
  font-size: 12px;
  font-weight: bold;
}
</style>