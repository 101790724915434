<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main app>
    <v-row justify="center" class="mb-7">
      <v-col :cols="$store.state.mobile? 12 : 6">
        <v-img
          :src="backendurl + '/images/277'"
          justify="center"
          dark
          color="primary"
          contain
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-7">
      <v-spacer />
      <v-col :cols="$store.state.mobile? 12 : 6">
        <v-card
          elevation="8"
          class="pb-5"
        >
        <v-card-title>ARCUS - Milano</v-card-title>
        <v-card-subtitle>Contatti</v-card-subtitle>
        <v-card-text v-html="contacttext">
        </v-card-text>
        <v-card-text>
          <div class="map-responsive">
            <!--iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.7920994744254!2d9.226614415961116!3d45.47399177910104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6f4b6a315b7%3A0x4be650cbbbfc1939!2sVia%20Luigi%20Mangiagalli%2C%2032%2C%2020133%20Milano%20MI!5e0!3m2!1sit!2sit!4v1606466977393!5m2!1sit!2sit" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe-->
            <iframe src="https://maps.googleapis.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.7920994744254!2d9.226614415961116!3d45.47399177910104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6f4b6a315b7%3A0x4be650cbbbfc1939!2sVia%20Luigi%20Mangiagalli%2C%2032%2C%2020133%20Milano%20MI!5e0!3m2!1sit!2sit!4v1606466977393!5m2!1sit!2sit" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
          <br>
          <br>
        </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
    </v-row>
  </v-main>
  <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import Footer from "./Footer";
import CookieLaw from 'vue-cookie-law'
const config = require("@/config");
const axios = require("axios");

export default {
  name: "Contatti",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    Footer,
    CookieLaw,
  },
  created: function () {
    var component = this
    axios
      .get(component.backendurl + "/sitesettings/contatti", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
            component.contacttext = component.formattedText(response.data.setting.text)
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    backendurl: config.backendurl,
    contacttext: '',
  }),
  methods: {
    formattedText (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
  },
};
</script>
<style>
.v-card .v-card__text,
.v-card .v-card__subtitle,
.v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333 !important;
}
.v-application .v-list-item a {
  text-decoration: none;
  color: #333333;
}
.map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
</style>
