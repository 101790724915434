<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
        <ArticleContent v-if="articleFound" :article="article" />
        <ArticleNoContent v-else />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import ArticleContent from "./ArticleContent";
import ArticleNoContent from "./ArticleNoContent";
import Footer from "./Footer";
import CookieLaw from 'vue-cookie-law'
const axios = require("axios");
const config = require("@/config");


axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";


export default {
  name: "App",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    ArticleContent,
    ArticleNoContent,
    Footer,
    CookieLaw,
  },
  created: function () {
    var component = this;
    // console.log(component.$route.params.id);
    axios
      .get(component.backendurl + "/contents/" + component.$route.params.id, { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
              component.articleFound = true
              component.article = response.data.content
              document.title = 'ARCUS - Milano - ' + component.article.title
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
              component.articleFound = false
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    article: {},
    backendurl: config.backendurl,
    articleFound: true
  }),
  methods: {
    onClick() {},
  },
};
</script>
