<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <v-container>
        <v-row justify="center" class="mb-7">
          <v-col :cols="$store.state.mobile? 12 : 6">
            <v-img
              :src="require('@/assets/gears.jpg')"
              justify="center"
              dark
              color="primary"
              contain
            >
            </v-img>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-7">
          <v-spacer />
          <v-col
            class="text-center justify-center"
            :cols="$store.state.mobile? 12 : 6"
            justify-self="center"
          >
          <v-card class="pa-2 pb-8" align="top" elevation="8">
              <br>
              <h3>Siamo spiacenti, si &egrave; verificato un inconveniente tecnico.<br>
              Si prega di riprovare a breve.</h3>
              <br>
              <br>
              <v-btn
                dark
                @click="$router.go(-1)"
              >
              RIPROVA
              </v-btn>
              <br>
          </v-card>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import Footer from "./Footer";
import CookieLaw from 'vue-cookie-law'

export default {
  name: "TechProblems",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    Footer,
    CookieLaw,
  },
  created: function () {
  },
  data: () => ({
  }),
  methods: {
  },
};
</script>
