<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <v-snackbar
        v-model="snackbar"
        timeout="5000"
        :color="snackbarColor"
      >
      {{ snackbarText }}
      </v-snackbar>
      <v-row justify="center">
        <v-spacer v-if="$store.state.mobile" />
        <v-col :cols="$store.state.mobile ? 12 : 6">
          <v-card v-if="submitted">
            <v-card-subtitle>
              &Egrave; stata inviata una email all'indirizzo e-mail specificato.<br>
              Seguire il link indicato nella e-mail per confermare la registrazione.<br>
            </v-card-subtitle>
          </v-card>
          <v-card v-else-if="wrongemail">
            <v-card-subtitle>
              Ll'indirizzo e-mail specificato non esiste tra gli utenti ARCUS - Milano.<br>
              Si pu&ograve; ottenere l'accesso registrandosi <a href="/register">qui</a>.<br>
            </v-card-subtitle>
          </v-card>
          <v-card v-else>
            <v-form @submit="onSubmit">
              <v-card-title class="align-center grey--text text--darken-2 px-4">
                <v-img
                  src="@/assets/logo-arcus-trans.png"
                  height="80"
                  max-width="80"
                  contain
                />
                ARCUS - Milano - Recupero password
              </v-card-title>
              <v-card-subtitle>
                Inserire il proprio indirizzo di email:
              </v-card-subtitle>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-text-field
                    id="email"
                    v-model="email"
                    :error-messages="emailErrors"
                    required
                    placeholder="email"
                    label="email"
                    autocapitalize="off"
                    spellcheck="false"
                    autocorrect="off"
                    :disabled="fieldsDisabled"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-btn
                    type="submit"
                    variant="primary"
                    outlined
                    :disabled="buttonDisabled"
                  >
                    <span v-if="loading" class="custom-loader">
                      <v-progress-circular indeterminate color="primary" />
                    </span>
                    <span v-else>Recupera</span>
                  </v-btn>
                </v-col>
              </v-row>
              <br />
              <v-alert
                v-if="dismissCountDown"
                dismissible
                transition="fade-transition"
                type="error"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import CookieLaw from "vue-cookie-law";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
const config = require("@/config");
const axios = require("axios");

export default {
  name: "Recover",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
  },

  data: () => ({
    email: "",
    dismissSecs: 3,
    dismissCountDown: 0,
    message: "",
    loading: false,
    buttonDisabled: true,
    fieldsDisabled: false,
    submitted: false,
    wrongemail: false,
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'primary'
  }),
  methods: {
    onSubmit(evt) {
      const component = this;
      evt.preventDefault();
      component.loading = true;
      component.buttonDisabled = true;
      component.fieldsDisabled = true;
      var tosubmit = {
        email: component.email,
      }
      // console.log(this.form)
      axios
        .put(config.backendurl + "/members/resetpassword", tosubmit, {
          headers: {
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            // TODO Messaggio di registrazione
            component.submitted = true
            component.wrongemail = false
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else if (response.data.httpStatus === 501) {
            // Utente già esistente
            component.snackbarText = "L'email indicata non esiste tra gli utenti ARCUS - Milano."
            component.submitted = false
            component.wrongemail = true
            component.snackbarColor = 'error'
            component.snackbar = true
          } else if (response.data.httpStatus === 502) {
            // Utente già esistente
            component.snackbarText = "Questa procedura non può essere utilizzata per il resupero della password degli account di Unimi"
            component.submitted = false
            component.wrongemail = true
            component.snackbarColor = 'error'
            component.snackbar = true
          } else {
            component.snackbarText = response.data.longMessage
            component.snackbarColor = 'error'
            component.snackbar = true
          }
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
        })
        .catch(function (response) {
          // error callback
          component.message = response;
          component.dismissCountDown = 4;
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
          console.log(response)
          component.$router.push({ path: '/problems' })
        });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      var component = this;
      var internalemail = "@UNIMI.IT"
      var studentemail = "@STUDENTI.UNIMI.IT"
      var unimi = false
      if (!this.$v.email.$dirty) return errors;
      if ((component.email.length - component.email.toUpperCase().search(internalemail)) === internalemail.length ||
          (component.email.length - component.email.toUpperCase().search(studentemail)) === studentemail.length) {
            errors.push("Questa procedura non è valida per gli account unimi")
            unimi = true
      }
      
      !this.$v.email.email && errors.push("E-mail non valida");
      !this.$v.email.required && errors.push("E-mail necessaria");
      if (
        !unimi &&
        this.$v.email.email &&
        this.$v.email.required
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
  },
};
</script>
