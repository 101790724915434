<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <v-snackbar
        v-model="snackbar"
        timeout="5000"
        :color="snackbarColor"
      >
      {{ snackbarText }}
      </v-snackbar>
      <v-row justify="center">
        <v-spacer v-if="$store.state.mobile" />
        <v-col :cols="$store.state.mobile ? 12 : 6">
          <v-card v-if="submitted">
            <v-card-subtitle>
              Grazie per la tua registrazione al sito ARCUS - Milano.<br><br>
              &Egrave; stata inviata una email all'indirizzo e-mail specificato.<br>
              Seguire il link indicato nella e-mail per confermare la registrazione.<br>
            </v-card-subtitle>
          </v-card>
          <v-card v-else>
            <v-form @submit="onSubmit">
              <v-card-title class="align-center grey--text text--darken-2 px-4">
                <v-img
                  src="@/assets/logo-arcus-trans.png"
                  height="80"
                  max-width="80"
                  contain
                />
                ARCUS - Milano - Registrazione
              </v-card-title>
              <v-card-subtitle>
                Inserire i seguenti campi per la registrazione:
              </v-card-subtitle>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-text-field
                    id="email"
                    v-model="email"
                    :error-messages="emailErrors"
                    required
                    placeholder="email"
                    label="email"
                    autocapitalize="off"
                    spellcheck="false"
                    autocorrect="off"
                    :disabled="fieldsDisabled"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  />
                  <v-text-field
                    id="firstname"
                    v-model="firstname"
                    :error-messages="firstnameErrors"
                    required
                    placeholder="Nome"
                    label="Nome"
                    autocapitalize="off"
                    spellcheck="false"
                    autocorrect="off"
                    :disabled="fieldsDisabled"
                    @input="$v.firstname.$touch()"
                    @blur="$v.firstname.$touch()"
                  />
                  <v-text-field
                    id="lastname"
                    v-model="lastname"
                    :error-messages="lastnameErrors"
                    required
                    placeholder="Cognome"
                    label="Cognome"
                    autocapitalize="off"
                    spellcheck="false"
                    autocorrect="off"
                    :disabled="fieldsDisabled"
                    @input="$v.lastname.$touch()"
                    @blur="$v.lastname.$touch()"
                  />
                  <v-text-field
                    id="password"
                    v-if="notunimi"
                    v-model="password"
                    :error-messages="passwordErrors"
                    type="password"
                    required
                    placeholder="Password"
                    label="Password"
                    :disabled="fieldsDisabled"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  />
                  <v-text-field
                    id="password2"
                    v-if="notunimi"
                    v-model="password2"
                    :error-messages="password2Errors"
                    type="password"
                    required
                    placeholder="Password (conferma)"
                    label="Password (conferma)"
                    :disabled="fieldsDisabled"
                    @input="$v.password2.$touch()"
                    @blur="$v.password2.$touch()"
                  />
                  <v-sheet v-else>
                    Password non necessaria, l'autenticazione avverrà utilizzando le credenziali di ateneo.
                  </v-sheet>

                  <v-checkbox
                    v-model="newsletter"
                    label="Iscrizione alla Newsletter"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-btn
                    type="submit"
                    variant="primary"
                    outlined
                    :disabled="buttonDisabled"
                  >
                    <span v-if="loading" class="custom-loader">
                      <v-progress-circular indeterminate color="primary" />
                    </span>
                    <span v-else>Registrati</span>
                  </v-btn>
                </v-col>
              </v-row>
              <br />
              <v-alert
                v-if="dismissCountDown"
                dismissible
                transition="fade-transition"
                type="error"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import CookieLaw from "vue-cookie-law";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
const config = require("@/config");
const axios = require("axios");
// const qs = require("querystring");

export default {
  name: "Register",
  mixins: [validationMixin],
  validations: {
    firstname: { required },
    lastname: { required },
    password: { required },
    password2: { required },
    email: { required, email },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
  },

  data: () => ({
    email: "",
    password: "",
    password2: "",
    firstname: "",
    lastname: "",
    dismissSecs: 3,
    dismissCountDown: 0,
    message: "",
    loading: false,
    buttonDisabled: true,
    fieldsDisabled: false,
    risposta: "",
    newsletter: 1,
    submitted: false,
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'primary'
  }),
  methods: {
    onSubmit(evt) {
      const component = this;
      evt.preventDefault();
      component.loading = true;
      component.buttonDisabled = true;
      component.fieldsDisabled = true;
      var tosubmit = {
        email: component.email,
        firstname: component.firstname,
        lastname: component.lastname,
        password: component.password,
        newsletter: component.newsletter,
      }
      // console.log(this.form)
      axios
        .post(config.backendurl + "/members/register", tosubmit, {
          headers: {
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            // TODO Messaggio di registrazione
            component.submitted = true
          } else if (response.data.httpStatus === 501) {
            // Utente già esistente
            component.snackbarText = "L'email indicata è già utilizzata, se non si ricordano le credenziali del sito ARCUS - Milano utilizzare la procedura di recupero password"
            component.snackbarColor = 'error'
            component.snackbar = true
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
            component.snackbarText = response.data.longMessage
            component.snackbarColor = 'error'
            component.snackbar = true
          }
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
        })
        .catch(function (response) {
          // error callback
          component.risposta = response;
          component.message = response;
          component.dismissCountDown = 4;
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
          console.log(response)
          component.$router.push({ path: '/problems' })
        });
    },
  },
  computed: {
    notunimi () {
      var internalemail = "@UNIMI.IT"
      var studentemail = "@STUDENTI.UNIMI.IT"
      var username = this.email
      // if ((username.length - username.toUpperCase().search(internalemail)) == internalemail.length ||
      //    (username.length - username.toUpperCase().search(studentemail)) == studentemail.length) {
      if(username.toUpperCase().endsWith(internalemail) || username.toUpperCase().endsWith(studentemail)) {
        return false
      } else {
        return true
      }


    },
    emailErrors() {
      const errors = [];
      var component = this;
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("E-mail non valida");
      !this.$v.email.required && errors.push("E-mail necessaria");
      if (
        this.$v.email.email &&
        this.$v.email.required &&
        this.$v.firstname.required && 
        this.$v.lastname.required &&
        ((this.$v.password.required &&
        this.password === this.password2 &&
        this.$v.password2.required) ||
        !this.notunimi)
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
    firstnameErrors() {
      const errors = [];
      var component = this;
      if (!this.$v.firstname.$dirty) return errors;
      !this.$v.firstname.required && errors.push("Nome necessario");
      if (
        this.$v.email.email &&
        this.$v.email.required &&
        this.$v.firstname.required && 
        this.$v.lastname.required &&
        ((this.$v.password.required &&
        this.password === this.password2 &&
        this.$v.password2.required) ||
        !this.notunimi)
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      var component = this;
      if (!this.$v.lastname.$dirty) return errors;
      !this.$v.lastname.required && errors.push("Cognome necessario");
      if (
        this.$v.email.email &&
        this.$v.email.required &&
        this.$v.firstname.required && 
        this.$v.lastname.required &&
        ((this.$v.password.required &&
        this.password === this.password2 &&
        this.password === this.password2 &&
        this.$v.password2.required) ||
        !this.notunimi)
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      var component = this;
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password necessaria");
      if (
        this.$v.email.email &&
        this.$v.email.required &&
        this.$v.firstname.required && 
        this.$v.lastname.required &&
        ((this.$v.password.required &&
        this.password === this.password2 &&
        this.$v.password2.required) ||
        !this.notunimi)
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
    password2Errors() {
      const errors = [];
      var component = this;
      if (!this.$v.password2.$dirty) return errors;
      !this.$v.password2.required && errors.push("Password necessaria");
      this.password !== this.password2 && errors.push("Le password non coincidono")
      if (
        this.$v.email.email &&
        this.$v.email.required &&
        this.$v.firstname.required && 
        this.$v.lastname.required &&
        ((this.$v.password.required &&
        this.password === this.password2 &&
        this.$v.password2.required) ||
        !this.notunimi)
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
  },
};
</script>
