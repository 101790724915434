<template>
  <v-col md="3">
    <v-lazy transition="fade-transition">
      <v-card class="pa-2 cardScroll" align="top" height="450" outlined elevation="16">
        <router-link :to="'/article/' + article.id">
          <v-img
            max-height="200"
            :src="backendurl + '/images/' + article.image"
            :href="'/article/' + article.id"
            contain
            class="pt-3"
          >
            <v-chip
              class="mx-1 px-1 mt-2"
              color="primary"
              rounded
              dark
              x-small
              v-for="(category, i) in article.categories"
              :key="i"
            >
              {{ category }}
            </v-chip>
            <v-img
              v-if="article.state=='suspended'"
              :src="require('@/assets/sospesa.png')"
              class="overlayimage"
            >
            </v-img>
            <v-img
              v-if="article.state=='canceled'"
              :src="require('@/assets/annullata.png')"
              class="overlayimage"
            >
            </v-img>
            <v-img
              v-if="article.state=='postponed'"
              :src="require('@/assets/rinviata.png')"
              class="overlayimage"
            >
            </v-img>
          </v-img>
        </router-link>

        <v-card-title class="subtitle-2"
          ><router-link :to="'/article/' + article.id">{{
            article.title
          }}</router-link></v-card-title
        >

        <!-- TODO: troncare testo -->
        <v-card-text>{{ minifyText(article.abstract) }} </v-card-text>
        <div class="text-center">
        <v-btn
        dark
        color="primary"
        :to="'/article/' + article.id"
        small
        >
        Leggi
        </v-btn>
        </div>
      </v-card>
    </v-lazy>
  </v-col>
</template>

<script>
const config = require("@/config");

export default {
  name: "HomeArticlesCol",
  components: {},
  props: ["article", "index"],
  created: function () {},
  data: () => ({
    backendurl: config.backendurl,
    contents: [],
    notices: [],
  }),
  methods: {
    minifyText: function (text) {
      if (text.length < 100) return text;
      return text.substring(0, 100) + "...";
    },
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.v-application a {
  text-decoration: none;
}
.cardScroll {
  overflow-y: hidden;
}
.overlayimage {
  margin-top: -30px;
}
</style>
