<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <v-container>
          <v-row justify="center" class="mb-7">
            <v-spacer />
            <v-col
              class="text-center justify-center"
              :cols="$store.state.mobile? 12 : 6"
              justify-self="center"
            >
            <v-card
              elevation="8"
            >
            <v-card-title v-if="loading">
            </v-card-title>
            <v-card-title v-else-if="photogalleries.length">
              Elenco Gallerie fotografiche
            <br>
            <br>
            <br>
            </v-card-title>
            <v-card-title v-else>
              Nessuna galleria fotografica presente
            </v-card-title>
            <v-card-text class="text-start">
            <span  
              v-for="(photogallery,i) in photogalleries"
              class="text-start"
              :key="i"
            >
             <a
              :href="'/gallery/' + photogallery.id"
            >
            {{ photogallery.title }}
            </a><br>
            </span>
            </v-card-text>
            </v-card>
            </v-col>
            <v-spacer />
          </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import CookieLaw from 'vue-cookie-law'
const axios = require("axios");
const config = require("@/config");


axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";


export default {
  name: "App",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
  },
  created: function () {
    var component = this;
    // console.log(component.$route.params.id);
    axios
      .get(component.backendurl + "/photogalleries", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
              component.photogalleries = response.data.photogalleries
              component.photogalleries.reverse()
              component.loading = false
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
              component.loading = false
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    photogalleries: [],
    backendurl: config.backendurl,
    articleFound: true,
    loading: true,
  }),
  methods: {
    onClick() {},
  },
};
</script>
<style scoped>
.v-application .v-card .v-card__text span a {
  color: black; 
}
</style>
