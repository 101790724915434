<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main
      v-if="loading"
    >
    </v-main>
    <v-main
      v-else-if="photogallery"
    >
      <TopBanner />
      <v-dialog
        v-model="fsImageDialog"
        fullscreen
        :scrollable="false"
      >
      <v-card
        style="background-color: rgba(20,20,20,0.9);"
      >
        <v-img
          :src="backendurl + '/pictures/' + pictureId"
          :max-height="windowHeight"
          :max-width="windowWidth"
          contain
        >
        <v-btn
            icon
            x-large
            dark
            @click="fsImageDialog = false"
          >
            <v-icon>mdi-close-circle</v-icon>
        </v-btn>

        </v-img>
      </v-card>
      </v-dialog>
      <v-row justify="center">
        <v-spacer />
        <v-col :cols="$store.state.mobile? 12 : 6">
          <v-card
            elevation="8"
          >
          <v-card-title>
            {{ photogallery.title }}
          </v-card-title>
          <v-card-subtitle>
            {{ photogallery.abstract}}
          </v-card-subtitle>
          <v-card-text>
          <carousel-3d
            v-if="photogallery"
            :autoplay="true"
            :autoplayHoverPause="true"
            :autoplayTimeout="3000"
            :controlsVisible="true"
            controlsWidth="20"
            controlsHeight="20"
            :loop="true"
            space="180"
            :clickable="true"
            width="500"
            :perPage="1"
            :scrollPerPage="true"
            border="2"
            @before-slide-change="onAfterSlideChange"
          >
            <slide
              v-for="(picture,i) in photogallery.pictures"
              :key="i"
              :index="i"
              style="background-color: white; vertical-align: middle;"
            >
              <v-img
                :src="backendurl + '/pictures/' + picture.id"
                @click="openImage(picture.id)"
                :aspect-ratio="16/9"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary lighten-5"
                    ></v-progress-circular>
                  </v-row>
              </template>
              </v-img>
            </slide>
          </carousel-3d>
          </v-card-text>
          <v-card-text class="text-center">
          {{ currentImage }}/{{ totalImages }}
          </v-card-text>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-main>
    <v-main v-else>
          <ArticleNoContent />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import ArticleNoContent from "./ArticleNoContent";
import CookieLaw from 'vue-cookie-law'
import { Carousel3d, Slide } from 'vue-carousel-3d';
const config = require("@/config");
const axios = require("axios");

export default {
  name: "Gallery",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
    Carousel3d,
    Slide,
    ArticleNoContent
  },
  created: function () {
    var component = this
    axios
            .get(component.backendurl + "/photogalleries/" + component.$route.params.id, { headers: { ARCUSSession: component.$session.id() } })
            .then(function (response) {
              if(response.data.httpStatus === 200) {
                component.photogallery = response.data.photogallery
                document.title = 'ARCUS - Milano - ' + component.photogallery.title
                component.totalImages = component.photogallery.pictures.length
                component.loading = false
              } else if(response.data.httpStatus === 503) {
                component.$router.push({ path: '/problems' })
              }
            })
            .catch(function (response) {
              console.log(response);
              component.$router.push({ path: '/problems' })
            });
  },
  data: () => ({
    photogallery: null,
    backendurl: config.backendurl,
    pictureId: null,
    fsImageDialog: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    totalImages: 0,
    currentImage: 1,
    loading: true,
  }),
  methods: {
    onAfterSlideChange (index) {
      this.currentImage = index + 1
    },
    onClick() {},
    openImage (id) {
      this.pictureId = id
      this.fsImageDialog = true
    },
  },
};
</script>
<style>
.carousel-3d-slide .container {
  background-color: rgba(200,200,200,0.7);
}
.carousel-3d-slide {
  background-color: rgba(200,200,200,0.7);
}
.carousel-3d-slide {
  border: 2px solid rgba(200,200,200,0.9);
}
.carousel-3d-slide .container {
  padding: 5px;
}
.carousel-3d-controls a.next span, .carousel-3d-controls a.prev span {
  color: #003366;
  padding: 0px;
} 
</style>
