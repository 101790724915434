import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/Home.vue'
import Article from './components/Article.vue'
import Wrong from './components/Wrong.vue'
import ChiSiamo from './components/ChiSiamo.vue'
import Contatti from './components/Contatti.vue'
import Login from './components/Login.vue'
import Logout from './components/Logout.vue'
import Profile from './components/Profile.vue'
import Register from './components/Register.vue'
import RegisterConfirm from './components/RegisterConfirm.vue'
import Recover from './components/Recover.vue'
import Gallery from './components/Gallery.vue'
import Galleries from './components/Galleries.vue'
import RecoverConfirm from './components/RecoverConfirm.vue'
import TechProblems from './components/TechProblems.vue'
const config = require("@/config");
const jwt = require('jsonwebtoken')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ARCUS - Milano - Home',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: Home
  },
  {
    path: '/galleries',
    name: 'ARCUS - Milano - Galleries',
    meta: {
      title: 'ARCUS - Milano - Gallery'
    },
    component: Galleries
  },
  {
    path: '/gallery/:id',
    name: 'ARCUS - Milano - Gallery',
    meta: {
      title: 'ARCUS - Milano - Gallery'
    },
    component: Gallery
  },
  {
    path: '/article/:id',
    name: 'ARCUS Milano - Articolo',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: Article
  },
  {
    path: '/problems',
    name: 'ARCUS Milano - Problemi tecnici',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: TechProblems
  },
  {
    path: '/recover',
    name: 'ARCUS Milano - Reset password',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: Recover
  },
  {
    path: '/recover/:code',
    name: 'ARCUS Milano - Conferma reset password',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: RecoverConfirm
  },
  {
    path: '/register',
    name: 'ARCUS Milano - Registrazione',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: Register
  },
  {
    path: '/register/:code',
    name: 'ARCUS Milano - Conferma registrazione',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: RegisterConfirm
  },
  {
    path: '/chisiamo',
    name: 'ARCUS - Milano - Chi Siamo',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: ChiSiamo
  },
  {
    path: '/profile',
    name: 'ARCUS - Milano - Profilo utente',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: Profile
  },
 {
  path: '/login',
  name: 'ARCUS - Milano - Login',
  meta: {
    title: 'ARCUS - Milano'
  },
  component: Login
},
{
  path: '/logout',
  name: 'ARCUS - Milano - Logout',
  meta: {
    title: 'ARCUS - Milano'
  },
  component: Logout
},
{
    path: '/contatti',
    name: 'ARCUS - Milano - Contatti',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: Contatti
  },
  {
    path: '*',
    name: 'Errore',
    meta: {
      title: 'ARCUS - Milano'
    },
    component: Wrong
  },
]

const router = new VueRouter({
    routes,
    mode: 'history'
  })
  
export default router

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/galleries', '/gallery','/logout', '/login', '/register', '/recover', '/contatti', '/chisiamo','/article','/problems']
  const privPages = ['/profile']
  const root = '/'
  var authRequired = !publicPages.includes(to.path)
  document.title = to.name
  for (var publicPage of publicPages) {
    if (to.path.search(publicPage) === 0) {
      authRequired = false
    }
  }
  if(to.path === root) {
    authRequired = false
  }
  var loggedIn = localStorage.getItem(config.authToken)
  if (loggedIn) {
    // console.log('admin_auth_token esiste: ' + loggedIn)
    loggedIn = loggedIn.toString()
    try {
      var payload = jwt.decode(loggedIn)
      if (payload.exp < Math.floor(Date.now() / 1000)) {
        // JWT expired
        localStorage.removeItem(config.authToken)
        // console.log('jwt scaduto')
        return next('/login')
      }
    } catch (error) {
      // Not a valid JWT
      // console.log('jwt non valido')
      localStorage.removeItem(config.authToken)
      return next('/login')
    }
    if (!authRequired) {
      // Pagina pubblica
      // console.log('pagina pubblica, e jwt valido: redirigo su / ?')
    }
  } else {
    // No JWT present in local storage
    // console.log('jwt non presente')
    var priv = false
    for (var privPage of privPages) {
      if (to.path.search(privPage) === 0) {
        priv = true
      }
    }
    if (authRequired && priv) {
      return next('/login')
    }
  }
  next()
})
