<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <HomeEmphasis />
      <HomeArticles />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import HomeArticles from "./HomeArticles";
import HomeEmphasis from "./HomeEmphasis";
import Footer from "./Footer";
import CookieLaw from 'vue-cookie-law'

export default {
  name: "App",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    HomeArticles,
    HomeEmphasis,
    Footer,
    CookieLaw,
  },

  data: () => ({
    //
  }),
  methods: {
    onClick() {}
  },
};
</script>
