import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n'
import { preset } from '../arcus-preset/preset'

Vue.use(Vuetify);
Vue.use(VueI18n)

export default new Vuetify({
  preset,
});
