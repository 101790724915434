<template>
<v-container fluid>
  <v-row justify="center" class="mb-7">
    <v-col :md="$store.state.mobile? 7 : 6" class="mx-1">
      <v-carousel
        height="350"
        cycle
        interval="5000"
        delimiter-icon="mdi-circle-small"
        color="primary"
      >
          <v-sheet dark color="primary" class="px-2 py-1 sm-1">AVVISI E NOTIZIE</v-sheet>
          <v-carousel-item
            v-for="(content, i) in $store.state.notices"
            :key="i"
            :index="i"
            :href="'/article/'+content.id"
          >
          <v-row justify="center">
            <v-col cols="4">
              <v-img
                :src="backendurl+'/images/'+ content.image"
                contain
                max-width="100%"
                max-height="100%"
              />
            </v-col>
            <v-col cols="8">
              <v-sheet light class="px-2 py-1 mx-4 sm-4">
                  <div :class="$store.state.mobile ? 'gray--text text--darken-2 text-subtitle-1' : 'gray--text text--darken-2 text-h5'" >{{ content.title }}</div>
                  <div :class="$store.state.mobile ? 'gray--text text--darken-2 text-caption' : 'gray--text text--darken-2 text--body-2'">{{ content.abstract }}</div>
              </v-sheet>
            </v-col>
          </v-row>
          
          </v-carousel-item>
            </v-carousel>
        <v-spacer />
    </v-col>
    <v-col :md="$store.state.mobile? 2 : 3" class="mx-1">
      <v-sheet dark color="primary" class="px-2 py-1 sm-1">PROSSIMI EVENTI</v-sheet>
      <v-container class="text-center" ref="rightcol">
      <HomeCalendar />
      </v-container>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
const config = require("@/config");
import HomeCalendar from "./HomeCalendar";


export default {
  name: "HomeEmphasis",
  components: { HomeCalendar },
  created: function () {
  },
  data: () => ({
      backendurl: config.backendurl,
      calendarEvents: [],
  }),
  methods: {
  },
};
</script>
<style>
  .v-card__title {
    color: white!important;
  }
  .text-clip {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-carousel__controls {
    height: 30px;
  }
  .v-carousel__controls .v-btn--icon.v-size--small .v-icon {
    height: 12px;
    width: 12px;
    font-size: 12px;
  }
  .v-carousel__controls .v-btn--icon.v-size--small {
    height: 12px;
    width: 12px;
    font-size: 12px;
  }
  .v-carousel .v-carousel__controls {
    background-color: rgba(0, 51, 102, 0.9);
  }
  .v-application .primary {
    opacity: 0.9;
    background-color: rgba(0, 51, 102, 0.9);
  }
</style>
