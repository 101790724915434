<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <v-snackbar
        v-model="snackbar"
        timeout="5000"
        :color="snackbarColor"
      >
      {{ snackbarText }}
      </v-snackbar>

      <v-dialog
        v-model="passwordDialog"
        scrollable
        persistent
        max-width="800"
      >
      <v-card
          elevation="10"
      >
        <v-card-title class="headline">
          <br>
          <br>
          Cambio password
        </v-card-title>
        <v-card-text>
          <br>
          <v-text-field
            id="oldpassword"
            v-model="oldpassword"
            type="password"
            outlined
            placeholder="Password attuale"
            label="Password attuale"
            :error-messages="oldpasswordErrors"
            @input="$v.oldpassword.$touch()"
            @blur="$v.oldpassword.$touch()"

          />
          <v-text-field
            id="newpassword"
            v-model="newpassword"
            type="password"
            outlined
            placeholder="Nuova password"
            label="Nuova password"
            :error-messages="newpasswordErrors"
            @input="$v.newpassword.$touch()"
            @blur="$v.newpassword.$touch()"
          />
          <v-text-field
            id="newpassword2"
            v-model="newpassword2"
            type="password"
            outlined
            placeholder="Nuova password (conferma)"
            label="Nuova password (conferma)"
            :error-messages="newpassword2Errors"
            @input="$v.newpassword2.$touch()"
            @blur="$v.newpassword2.$touch()"
          />

        </v-card-text>
        <v-card-actions>
          <v-spacer />
            <v-btn
            color="primary"
            :disabled="buttonDisabled"
            @click="passwordChange()"
            >
            OK
            </v-btn>
            <v-btn
              color="primary"
              @click="passwordDialog = false"
            >
            Annulla
            </v-btn>

        </v-card-actions>
      </v-card>
      </v-dialog>

      <v-row justify="center" class="mb-7">
      <v-spacer />
      <v-col
        class="text-center justify-center"
        :cols="$store.state.mobile? 12 : 6"
        justify-self="center"
      >
      <v-card class="pa-2 pb-8" align="top" elevation="8">
        <v-card-title>Profilo utente</v-card-title>
        <v-row justify="end">
          <v-col
            v-if="externalUser"
            cols="2"
          >
            <v-btn
              color="primary"
              @click="passwordDialog = true"
            >
              Cambia password
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="$router.push({ path: '/logout' })" 
                    >
                      <v-icon large color="primary">
                        mdi-exit-run
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Esci</span>
                </v-tooltip>

          </v-col>
        </v-row>
        <v-card-text>
        <v-row v-if="userdata.lastname">
          <v-col cols="6" class="text-right">
            Cognome:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.lastname }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.firstname">
          <v-col cols="6" class="text-right">
            Nome:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.firstname }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.email">
          <v-col cols="6" class="text-right">
            e-mail:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.email }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.username">
          <v-col cols="6" class="text-right">
            Username:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.username }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="text-right">
            Iscritto alla Newsletter:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2 verticaltop" justify-self="start" >
            <v-switch
              v-model="userdata.newsletter"
              :label="userdata.newsletter? 'SI' : 'NO'"
              @change="newsletterChange"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="userdata.cardnumber">
          <v-col cols="6" class="text-right">
            Tessera ARCUS:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.cardnumber }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.type">
          <v-col cols="6" class="text-right">
            Qualifica:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.type }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.state">
          <v-col cols="6" class="text-right">
            Stato:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.state }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.membershipdeadline">
          <v-col cols="6" class="text-right">
            Scadenza iscrizione:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ membershipdeadline }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.anciucard">
          <v-col cols="6" class="text-right">
            Tessera ANCIU:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.anciucard }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.fiscalcode">
          <v-col cols="6" class="text-right">
            Codice fiscale:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.fiscalcode }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.birthstate">
          <v-col cols="6" class="text-right">
            Nazione di nascita:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.birthstate }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.birthplace">
          <v-col cols="6" class="text-right">
            Luogo di nascita:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.birthplace }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.birthprovince">
          <v-col cols="6" class="text-right">
            Provincia di nascita:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.birthprovince }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.birthdate">
          <v-col cols="6" class="text-right">
            Data di nascita:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ birthdate }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.address">
          <v-col cols="6" class="text-right">
            Indirizzo:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.address }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.postalcode">
          <v-col cols="6" class="text-right">
            CAP:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.postalcode }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.city">
          <v-col cols="6" class="text-right">
            Citt&agrave;:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.city }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.province">
          <v-col cols="6" class="text-right">
            Provincia:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.province }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.phone">
          <v-col cols="6" class="text-right">
            Telefono:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.phone }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.mobile">
          <v-col cols="6" class="text-right">
            Cellulare:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.mobile }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.workplace">
          <v-col cols="6" class="text-right">
            Sede di lavoro:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.workplace }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.employeenumber">
          <v-col cols="6" class="text-right">
            Numero di matricola:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.employeenumber }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.workaddress">
          <v-col cols="6" class="text-right">
            Indirizzo lavoro:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.workaddress }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.workpostalcode">
          <v-col cols="6" class="text-right">
            CAP lavoro:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.workpostalcode }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.workphone">
          <v-col cols="6" class="text-right">
            Telefono lavoro:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.workphone }}
          </v-col>
        </v-row>
        <v-row v-if="userdata.workfax">
          <v-col cols="6" class="text-right">
            FAX lavoro:
          </v-col>
          <v-col cols="6" class="text-left text-subtitle-2">
            {{ userdata.workfax }}
          </v-col>
        </v-row>
        </v-card-text>
        <v-card-subtitle class="text-center">
          Se riscontri anomalie nei dati sopra riportati contattaci all'indirizzo e-mail arcus@unimi.it
        </v-card-subtitle>
      </v-card>
      </v-col>
      <v-spacer />
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import CookieLaw from "vue-cookie-law";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
const config = require("@/config")
const axios = require("axios")
const dateFormat = require('dateformat')
dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab',
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',
    ],
    monthNames: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic',
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  }


export default {
  name: "Profile",
  mixins: [validationMixin],
  validations: {
    newpassword: { required },
    newpassword2: { required },
    oldpassword: { required },
  },
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
  },

  data: () => ({
    email: "",
    password: "",
    dismissSecs: 3,
    dismissCountDown: 0,
    message: "",
    loading: false,
    buttonDisabled: true,
    fieldsDisabled: false,
    risposta: "",
    userdata: {},
    oldpassword: '',
    newpassword: '',
    newpassword2: '',
    passwordDialog: false,
    externalUser: false,
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'primary'
  }),
  created: function () {
    const component = this
    var currentJWT = localStorage.getItem(config.authToken)
    axios
        .get(config.backendurl + "/members/my", {
          headers: {
            authorization: 'Bearer ' + currentJWT,
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            localStorage.setItem(config.authToken, response.data.jwt)
            component.userdata = response.data.data
            var internalemail = "@UNIMI.IT"
            var studentemail = "@STUDENTI.UNIMI.IT"
            var username = component.userdata.username

            if ((username.length - username.toUpperCase().search(internalemail)) !== internalemail.length &&
                (username.length - username.toUpperCase().search(studentemail)) !== studentemail.length) {
                  // external user
              component.externalUser = true
            } else {
              component.externalUser = false
            }
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
            console.log(response.data)
          }
        })
        .catch(function (response) {
          // error callback
          console.log(response)
        });
  },
  methods: {
    newsletterChange() {
      const component = this;
      var currentJWT = localStorage.getItem(config.authToken)
      component.loading = true;
      component.buttonDisabled = true;
      component.fieldsDisabled = true;
      var tosubmit = {
        newsletter: this.userdata.newsletter,
      }
      // console.log(this.form)
      axios
        .put(config.backendurl + "/members/newsletter", tosubmit, {
          headers: {
              authorization: 'Bearer ' + currentJWT,          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            // TODO Messaggio di errore
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          }
        })
        .catch(function (response) {
          // error callback
          console.log(response)
          component.$router.push({ path: '/problems' })
        });
    },
    passwordChange() {
      const component = this;
      var currentJWT = localStorage.getItem(config.authToken)
      component.loading = true;
      component.buttonDisabled = true;
      component.fieldsDisabled = true;
      var tosubmit = {
        oldpassword: this.oldpassword,
        newpassword: this.newpassword,
      }
      // console.log(this.form)
      axios
        .put(config.backendurl + "/members/password", tosubmit, {
          headers: {
              authorization: 'Bearer ' + currentJWT,          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            // TODO Messaggio di password cambiata
              component.snackbarText = 'Password modificata correttamente'
              component.snackbarColor = 'primary'
              component.snackbar = true

            component.passwordDialog = false
          } else {
            // TODO Messaggio di errore
            component.snackbarText = response.data.longMessage
            component.snackbarColor = 'error'
            component.snackbar = true
          }
        })
        .catch(function (response) {
          // error callback
          console.log(response)
          component.snackbarText = 'Errore di rete'
          component.snackbarColor = 'error'
          component.snackbar = true
          component.passwordDialog = false
        });
    },
    checkDialogErrors () {
        if (
          this.$v.oldpassword.required &&
          this.$v.newpassword.required &&
          this.$v.newpassword2.required &&
          this.newpassword === this.newpassword2
        ) {
          this.buttonDisabled = false
        } else {
          this.buttonDisabled = true
        }
      },

  },
  computed: {
    membershipdeadline () {
      return dateFormat(new Date(this.userdata.membershipdeadline), 'd mmmm yyyy')
    },
    birthdate () {
      return dateFormat(new Date(this.userdata.birthdate), 'd mmmm yyyy')
    },
    oldpasswordErrors: {
        get: function () {
          const errors = []
          if (!this.$v.oldpassword.$dirty) return errors
          !this.$v.oldpassword.required  && errors.push('Password necessaria')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
    newpasswordErrors: {
        get: function () {
          const errors = []
          if (!this.$v.newpassword.$dirty) return errors
          !this.$v.newpassword.required && errors.push('Password necessaria')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      newpassword2Errors: {
        get: function () {
          const errors = []
          if (!this.$v.newpassword2.$dirty) return errors
          !this.$v.newpassword2.required && errors.push('Password necessaria')
          this.newpassword !== this.newpassword2 && errors.push('Le password non coincidono')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },

  },
};
</script>
<style>
.verticaltop .v-input--selection-controls {
  padding-top: 0px;
  padding-bottom: 0px;
}
.verticaltop {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
