<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <v-row justify="center">
        <v-spacer v-if="$store.state.mobile" />
        <v-col :cols="$store.state.mobile ? 12 : 6">
          <v-card>
            <v-form class="loginform" @submit="onSubmit">
              <v-card-title class="align-center grey--text text--darken-2 px-4">
                <v-img
                  src="@/assets/logo-arcus-trans.png"
                  height="80"
                  max-width="80"
                  contain
                />
                ARCUS - Milano
              </v-card-title>
              <v-card-subtitle>
                <ul>
                <li>Utilizzare le proprie credenziali di accesso al sito ARCUS - Milano.<br>Per i dipendenti dell'Universit&agrave; degli Studi di Milano queste coincidono con le credenziali di Ateneo.</li>
                <li>I dipendenti che non ricordassero le credenziali possono utilizzare la procedura di recupero password <a href="https://auth.unimi.it/password/index.php">qui</a>.</li>
                <li>Gli utenti esterni che non ricordassero le proprie credenziali possono utilizzare la procedura di recupero password <a href="/recover">qui</a>.</li>
                <br>
                <li><strong>Se non sei socio ARCUS, n&eacute; registrato al sito ARCUS puoi registrarti <a href="/register">qui</a>.</strong></li>
                </ul>
              </v-card-subtitle>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-text-field
                    id="email"
                    v-model="email"
                    :error-messages="emailErrors"
                    required
                    placeholder="email"
                    autocapitalize="off"
                    spellcheck="false"
                    autocorrect="off"
                    :disabled="fieldsDisabled"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-text-field
                    id="password"
                    v-model="password"
                    :error-messages="passwordErrors"
                    type="password"
                    required
                    placeholder="password"
                    :disabled="fieldsDisabled"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-btn
                    type="submit"
                    variant="primary"
                    outlined
                    :disabled="buttonDisabled"
                  >
                    <span v-if="loading" class="custom-loader">
                      <v-progress-circular indeterminate color="primary" />
                    </span>
                    <span v-else>Login</span>
                  </v-btn>
                </v-col>
              </v-row>
              <br />
              <v-alert
                v-if="dismissCountDown"
                dismissible
                transition="fade-transition"
                type="error"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import CookieLaw from "vue-cookie-law";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
const config = require("@/config");
const axios = require("axios");
const qs = require("querystring");

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    password: { required },
    email: { required, email },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
  },

  data: () => ({
    email: "",
    password: "",
    dismissSecs: 3,
    dismissCountDown: 0,
    message: "",
    loading: false,
    buttonDisabled: true,
    fieldsDisabled: false,
    risposta: "",
  }),
  methods: {
    onSubmit(evt) {
      const component = this;
      evt.preventDefault();
      component.loading = true;
      component.buttonDisabled = true;
      component.fieldsDisabled = true;
      // console.log(this.form)
      var parameters = { username: this.email, password: this.password };
      axios
        .post(config.backendurl + "/login", qs.stringify(parameters), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
          console.log(response.data);
          if (response.data.httpStatus === 200) {
            // emit the auth event that will be listened by other components
            localStorage.setItem(config.authToken, response.data.jwt);
            // component.$router.push({ path: "/" });
            component.$router.go(-1)
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
            component.dismissCountDown = 4;
            component.message = response.data.longMessage;
            setTimeout(() => {
              component.dismissCountDown = 0;
            }, 4000);
          }
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
        })
        .catch(function (response) {
          // error callback
          component.risposta = response;
          component.message = response;
          component.dismissCountDown = 4;
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
          component.$router.push({ path: '/problems' })
        });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      var component = this;
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("E-mail non valida");
      !this.$v.email.required && errors.push("E-mail necessaria");
      if (
        this.$v.email.email &&
        this.$v.email.required &&
        this.$v.password.required
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      var component = this;
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password necessaria");
      if (
        this.$v.email.email &&
        this.$v.email.required &&
        this.$v.password.required
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
  },
};
</script>
<style>
.v-application .v-card form.loginform a {
  color: #003366;
  font-size: 115%;
  font-weight: bold;
  text-decoration: none;
}
</style>
