<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
      <TopBanner />
      <v-snackbar
        v-model="snackbar"
        timeout="5000"
        :color="snackbarColor"
      >
      {{ snackbarText }}
      </v-snackbar>
      <v-row justify="center">
        <v-spacer v-if="$store.state.mobile" />
        <v-col :cols="$store.state.mobile ? 12 : 6">
          <v-card v-if="wrongcode">
            <v-card-subtitle>
              Il codice non &egrave; valido o scaduto.<br>Si prega di ripetere la procedura di recupero.<br>
            </v-card-subtitle>
          </v-card>
          <v-card v-else-if="submitted">
            <v-card-subtitle>
              La password &egrave; stata modificata correttamente.<br>
              A breve verrai rediretto sulla home page del sito.<br><br>
              Grazie, lo staff di ARCUS - Milano.<br>
            </v-card-subtitle>
          </v-card>
          <v-card v-else>
            <v-form @submit="onSubmit">
              <v-card-title class="align-center grey--text text--darken-2 px-4">
                <v-img
                  src="@/assets/logo-arcus-trans.png"
                  height="80"
                  max-width="80"
                  contain
                />
                ARCUS - Milano - Recupero password
              </v-card-title>
              <v-card-subtitle>
                Inserire i la nuova password:
              </v-card-subtitle>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-text-field
                    id="password"
                    v-model="password"
                    :error-messages="passwordErrors"
                    type="password"
                    required
                    placeholder="Password"
                    label="Password"
                    :disabled="fieldsDisabled"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  />
                  <v-text-field
                    id="password2"
                    v-model="password2"
                    :error-messages="password2Errors"
                    type="password"
                    required
                    placeholder="Password (conferma)"
                    label="Password (conferma)"
                    :disabled="fieldsDisabled"
                    @input="$v.password2.$touch()"
                    @blur="$v.password2.$touch()"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" class="mx-2">
                  <v-btn
                    type="submit"
                    variant="primary"
                    outlined
                    :disabled="buttonDisabled"
                  >
                    <span v-if="loading" class="custom-loader">
                      <v-progress-circular indeterminate color="primary" />
                    </span>
                    <span v-else>OK</span>
                  </v-btn>
                </v-col>
              </v-row>
              <br />
              <v-alert
                v-if="dismissCountDown"
                dismissible
                transition="fade-transition"
                type="error"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";
import Footer from "./Footer";
import CookieLaw from "vue-cookie-law";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
const config = require("@/config");
const axios = require("axios");
// const qs = require("querystring");

export default {
  name: "RecoverConfirm",
  mixins: [validationMixin],
  validations: {
    password: { required },
    password2: { required },
  },
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    TopBanner,
    Footer,
    CookieLaw,
  },

  data: () => ({
    password: "",
    password2: "",
    dismissSecs: 3,
    dismissCountDown: 0,
    message: "",
    loading: false,
    buttonDisabled: true,
    fieldsDisabled: false,
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'primary',
    wrongcode: false,
    submitted: false,
  }),
  methods: {
    onSubmit(evt) {
      const component = this;
      evt.preventDefault();
      component.loading = true;
      component.buttonDisabled = true;
      component.fieldsDisabled = true;
      var tosubmit = {
        password: component.password,
      }
      // console.log(this.form)
      axios
        .put(config.backendurl + "/members/resetpassword/" + component.$route.params.code, tosubmit, {
          headers: {
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            component.submitted = true
            component.wrongcode = false
            setTimeout(function(){ component.$router.push({ path: '/' }) }, 6000);
          } else if (response.data.httpStatus === 501) {
            // Utente già esistente
            component.snackbarText = "Il codice non è corretto o è scaduto."
            component.snackbarColor = 'error'
            component.snackbar = true
            component.submitted = false
            component.wrongcode = true
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
            component.snackbarText = response.data.longMessage
            component.snackbarColor = 'error'
            component.snackbar = true
          }
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
        })
        .catch(function (response) {
          // error callback
          component.message = response;
          component.dismissCountDown = 4;
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
        });
    },
  },
  created: function() {
    const component = this;
      component.loading = true;
      component.buttonDisabled = true;
      component.fieldsDisabled = true;
      // console.log(this.form)
      axios
        .get(config.backendurl + "/members/checkresetpassword/" + component.$route.params.code, {
          headers: {
          },
        })
        .then(function (response) {
          if (response.data.httpStatus === 200) {
            // TODO Messaggio di password cambiata
            component.submitted = false
            component.wrongcode = false
          } else if (response.data.httpStatus === 501) {
            // Utente già esistente
            component.snackbarText = "Il codice non è corretto o è scaduto."
            component.snackbarColor = 'error'
            component.snackbar = true
            component.submitted = false
            component.wrongcode = true
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
            component.snackbarText = response.data.longMessage
            component.snackbarColor = 'error'
            component.snackbar = true
          }
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
        })
        .catch(function (response) {
          // error callback
          component.message = response;
          component.dismissCountDown = 4;
          component.loading = false;
          component.buttonDisabled = false;
          component.fieldsDisabled = false;
          console.log(response)
          component.$router.push({ path: '/problems' })
        });
  },
  computed: {
    passwordErrors() {
      const errors = [];
      var component = this;
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password necessaria");
      if (
        this.password === this.password2 &&
        this.$v.password.required &&
        this.$v.password2.required
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
    password2Errors() {
      const errors = [];
      var component = this;
      if (!this.$v.password2.$dirty) return errors;
      !this.$v.password2.required && errors.push("Password necessaria");
      this.password !== this.password2 && errors.push("Le password non coincidono")
      if (
        this.password === this.password2 &&
        this.$v.password.required &&
        this.$v.password2.required
      ) {
        component.buttonDisabled = false;
      } else {
        component.buttonDisabled = true;
      }
      return errors;
    },
  },
};
</script>
