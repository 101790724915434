<template>
  <v-app>
    <cookie-law
      buttonText="OK, accetto"
      theme="arcus"
      message="Questo sito usa cookie (tecnici) ed altri strumenti  per assicurare la migliore esperienza di navigazione."
    />
    <TopBar />
    <v-main>
        <ArticleNoContent />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import TopBar from "./TopBar";
import ArticleNoContent from "./ArticleNoContent";
import Footer from "./Footer";
import CookieLaw from 'vue-cookie-law'

export default {
  name: "Wrong",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
    TopBar,
    ArticleNoContent,
    Footer,
    CookieLaw,
  },
  created: function () {
  },
  data: () => ({
  }),
  methods: {
  },
};
</script>
