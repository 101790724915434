import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categorySelected: "TUTTO",
    allContents: [],
    contents: [],
    notices: [],
    mobile: false,
    links: [
      {
        text: "HOME",
        href: "/",
        icon: "mdi-home",
        iconalt: "mdi-home",
      },
      {
        text: "CHI SIAMO",
        href: "/chisiamo",
        icon: "mdi-account-group",
        iconalt: "mdi-account-group",
      },
      {
        text: "CONTATTI",
        href: "/contatti",
        icon: "mdi-card-account-mail-outline",
        iconalt: "mdi-card-account-mail-outline",
      },
      {
        text: "GALLERY",
        href: "/galleries",
        icon: "mdi-image-multiple-outline",
        iconalt: "mdi-image-multiple-outline",
      },
      {
        text: "PROFILO",
        href: "/profile",
        icon: "mdi-account-lock",
        iconalt: "mdi-account-check",
        desktopicon: 1,
      },
    ],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
