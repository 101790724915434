<template>
  <v-container>
    <v-card-title>ORGANI</v-card-title>
    <v-card-text v-for="(board,i) in boards"
      :key="i"
    >
    <h2>{{ board.name }}</h2>
    <v-row>
    <v-col cols="10">
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr
              v-for="(member,j) in board.components"
              :key="j"
            >
              <td width="60%">{{ member.firstname }} {{ member.lastname}}</td>
              <td width="40%">{{ member.role }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
const axios = require("axios");
const config = require("@/config");

export default {
  name: "Presentazione",
  meta: {
    title: "ARCUS - Milano",
  },
  components: {
  },
  created: function () {
    var component = this
    axios
      .get(component.backendurl + "/boards", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
            component.boards = response.data.boards
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  data: () => ({
    boards: [],
    backendurl: config.backendurl,
  }),
  methods: {
  },
};
</script>
<style >
.v-card .v-card__text,
.v-card .v-card__subtitle,
.v-card .v-card__title {
  word-break: normal; /* maybe !important  */
  color: #333333 !important;
}
.v-application .v-list-item a {
  text-decoration: none;
  color: #333333;
}
</style>
