<template>
  <v-img
    :src="backendurl + '/images/' + bannerimage"
    height="120"
    justify="center"
    dark
    color="primary"
    gradient="to bottom, rgba(0, 51, 102, 0.7), rgba(0, 51, 102, 0.7)"
  >
    <v-row align="start" justify="center" >
      <v-spacer v-if="!$store.state.mobile"></v-spacer>
      <v-col :cols="$store.state.mobile? '6': '4'" dark class="text-right" justify="end" >
        <a 
          @click="goToUnimi()"
        >
        <v-img
        height="100"
        max-height="100"
          :src="require('@/assets/logo-unimi.png')"
          contain
          justify-self="center"
        >
        </v-img>
        </a>
      </v-col>
      <v-spacer v-if="$store.state.mobile"></v-spacer>
      <v-col :cols="$store.state.mobile? '6': '4'" dark class="text-left" justify="center" height="200">
        <!--h2>ASSOCIAZIONE RICREATIVA CULTURALE<br/>UNIVERSIT&Agrave; STATALE MILANO</h2-->
        <v-img
        height="100"
        max-height="100"
          :src="require('@/assets/logo-arcus-con scritta.png')"
          contain
          justify-self="start"
        >
        </v-img>
      </v-col>
      <v-spacer v-if="!$store.state.mobile"></v-spacer>
      
    </v-row>
  </v-img>
</template>

<script>
const axios = require("axios");
const config = require("@/config");
export default {
  name: "TopBanner",
  data: () => ({
    bannerimage: 278,
    backendurl: config.backendurl,
  }),
  created: function () {
    var component = this
    axios
      .get(component.backendurl + "/sitesettings/bannerimages", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
          if(response.data.httpStatus === 200) {
            if (response.data.setting.type === 'fixed') {
              component.bannerimage = response.data.setting.selected
            } else {
              component.bannerimage = response.data.setting.rotationimages[Math.floor(Math.random() * response.data.setting.rotationimages.length)]
            }
          } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
          } else {
            // component.bannerimage = component.backendurl + "/images/" +
          }
          
          // console.log(component.article)
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
  },
  methods: {
    goToUnimi () {
      window.location.href='https://www.unimi.it'
    },
},
};
</script>
