<template>
  <!--v-app-bar
      app
      color="primary"
      dark
      src="@/assets/duomoblu.jpg"
      shrink-on-scroll
      floating
    -->
  <v-app-bar
    app
    height="65"
    hide-on-scroll
  >
    <v-row justify="center">
      <v-spacer v-if="!$store.state.mobile" />
      <v-col :cols="$store.state.mobile? 9 : 5" justify="start" align-self="center" :class="$store.state.mobile? 'mx-0 px-0 md-3' : 'md-3'">
        <v-row>
          <v-col class="align-center grey--text text--darken-2 farleft">
          <!--router-link :to="'/'">
          <v-img
            alt="Arcus Logo"
            class="shrink mr-2"
            contain
            :src="require('@/assets/logo-arcus-trans.png')"
            transition="scale-transition"
            height="70"
            width="70"
          />
          </router-link-->


          <router-link :to="'/'">
          <h1 v-if="$store.state.mobile" class="text-subtitle-2">ARCUS Milano A.P.S.</h1>
          <h1 v-else class="display-1">ARCUS Milano A.P.S.</h1>
          </router-link>
          </v-col>
        </v-row>
      </v-col>

      <v-spacer />

      <v-col :cols="$store.state.mobile? 2 : 6" align-self="center" justify="end" class="md-3 mx-2">
        <v-row
          >
          <v-col cols="12" class="farright">
          <MenuDesktop v-if="!$store.state.mobile" />
          <MenuMobile v-else />
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
  </v-app-bar>
</template>

<script>
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
export default {
  name: "TopBar",
  components: {
    MenuDesktop,
    MenuMobile,
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>
<style scoped>
  .farright {
    text-align: right;
  }
  .farleft {
    text-align: left;
  }
  .farleft .v-image {
    margin-top: 8px;
  }
  .v-application a {
    text-decoration: none;
    color: #616161;
  }
  .row .col {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  header .row {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
