import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import VCalendar from 'v-calendar';
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueSession from 'vue-session'

Vue.use(VueYouTubeEmbed)

Vue.use(VueSession)

Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
