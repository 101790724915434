<template>
  <v-container>
    <v-row 
      v-if="!$store.state.mobile"
      justify="center"
    >
      <v-btn
        primary
        small
        :dark="$store.state.categorySelected === category.name"
        :color="$store.state.categorySelected === category.name ? 'primary' : ''"
        class="mx-1 px-1 py-1"
        v-for="(category, i) in categories"
        :key="i"
        @click="setSelected(category.name)"
        style="border-radius: 12px;"
        :class="
          $store.state.categorySelected != category.name
            ? 'grey--text text--darken-2'
            : 'white--text text--darken-1'
        "
      >
        {{ category.name }}
      </v-btn>
    </v-row>
    <v-row
      v-else
      justify="center"
    >
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-container class="text-center">
        <v-btn
          icon
          text
          v-bind="attrs"
          v-on="on"
          width="100"
        >
        <v-badge
          v-if="$store.state.categorySelected !== 'TUTTO'"
              color="none"
            light
            overlap
            bordered
            icon="mdi-check-bold"
          >

            <v-icon
              large
            >
              mdi-view-grid
            </v-icon>
          </v-badge>

          <v-icon
            v-else
            large
          >
            mdi-view-grid
          </v-icon>
        </v-btn>
          <br>
          <span class="text-caption my-2" >CATEGORIE</span>
        </v-container>
      </template>
      <v-list>
        <v-list-item
          v-for="(category, i) in categories"
          :key="i"
        >
          <v-btn
            primary
            small
            class="mx-1 px-1 py-1"
            @click="setSelected(category.name)"
            :class="
              $store.state.categorySelected != category.name
                ? 'grey--text text--darken-2'
                : 'indigo--text text--darken-1'
            "
          >
            {{ category.name }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
const config = require("@/config");

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

function prepareSelectedArticles(contents, category) {
  var counter = 0;
  var rowCounter = -1;
  var rows = [];
  contents.forEach(function (article) {
    if (category == "TUTTO" || article.categories.includes(category)) {
      if (counter % 3 == 0) {
        rowCounter += 1;
        rows[rowCounter] = [];
      }
      counter += 1;
      rows[rowCounter].push(article);
    }
  });
  return rows;
}

function getContents(contents) {
  var rows = [];
  contents.forEach(function (content) {
    rows.push(content);
  });
  return rows;
}


export default {
  name: "CategoryChooser",
  created: function () {
    var component = this;
    axios
      .get(component.backendurl + "/categories", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
        if(response.data.httpStatus === 200) {
          response.data.categories.forEach(function (category) {
            component.categories.push(category);
          });
        } else if(response.data.httpStatus === 503) {
            component.$router.push({ path: '/problems' })
        }
        //component.categories[0] = { id: 0, name: "TUTTO", description: null }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });
    axios
      .get(component.backendurl + "/contents", { headers: { ARCUSSession: component.$session.id() } })
      .then(function (response) {
        if(response.data.httpStatus === 200) {
          component.$store.state.allContents = getContents(
            response.data.contents
          );
          component.$store.state.notices = getContents(
            response.data.notices
          );
          // console.log(component.$store.state.notices)
          component.setSelected("TUTTO");
        } else if(response.data.httpStatus === 503) {
          component.$router.push({ path: '/problems' })
        }
      })
      .catch(function (response) {
        console.log(response);
        component.$router.push({ path: '/problems' })
      });

    
  },
  data: () => ({
    backendurl: config.backendurl,
    categories: [{ id: 0, name: "TUTTO", description: null }, { id: -1, name: "NOVITÀ", description: null }],
  }),
  methods: {
    setSelected(name) {
      var component = this;
      component.$store.state.contents = prepareSelectedArticles(
        component.$store.state.allContents,
        name
      );
      component.$store.state.categorySelected = name
    },
  },
};
</script>
<style lang="scss">
.v-badge__wrapper .v-badge__badge {
  color: #003366;
  background-color: transparent;
  border-radius: 0px;
  height: 27px;
}
.row {
  margin: 0px;
}
</style>